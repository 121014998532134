import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import NavbarLinks from './NavbarLinks';
import UIBackButton from './UI/UIBackButton';
import { Modal } from '..';

export default function Header() {
    let { catalog } = useParams();
    const [openMenu, setOpenMenu] = useState(false);

    const [sideBar, setSideBar] = useState(false);

    return (
        <header>
            {/* NEWSIDEBAR */}
            {sideBar && (
                <Modal>
                    <div className='fixed flex font-semibold uppercase tracking-wide text-slate-600  flex-col text-sm gap-4 animate-fade-in-right rounded-l-xl p-6 min-w-[280px] top-0 z-[999999] bg-white right-0 bottom-0'>

                        {/* close */}
                        <div className='absolute cursor-pointer top-5 right-5' onClick={() => setSideBar(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>

                        {/* inicio */}
                        <Link className='duration-300 hover:text-primary' onClick={() => setSideBar(false)} to={`/${catalog}/`}>Catálogo</Link>

                        {/* herramientas */}
                        {catalog === 'spmk' && <div className='font-bold tracking-wide uppercase text-xxs text-slate-400' onClick={()=>setSideBar(false)}>herramientas</div>}
                        <Link to={`/${catalog}/piramide`} className='duration-300 hover:text-primary' onClick={()=>setSideBar(false)}>Pirámide de Precios</Link>
                        {catalog === 'spmk' && <a href='https://drive.google.com/file/d/11JX75xrSlUGM4jIcCeZKqgxKmNckyABk/view?usp=sharing'
                            target='_blank' rel='noreferrer' className='duration-300 hover:text-primary' onClick={()=>setSideBar(false)}>Grilla</a>}
                        {catalog === 'spmk' &&<Link to={`/${catalog}/ejecucion-cooler`} className='duration-300 hover:text-primary' onClick={()=>setSideBar(false)}>Ejecución Coolers</Link>}
                        {catalog === 'spmk' &&<Link to={`/${catalog}/plan-gary`} className='duration-300 hover:text-primary' onClick={()=>setSideBar(false)}>Plan Gary</Link>}
                        <div className='w-full h-[1px] bg-slate-200'></div>
                        {catalog === 'koe' && <a href='https://drive.google.com/file/d/1bvkmTan74tgBrsdHQp3-zZxH_NBPcwKn/view?usp=sharing'
                            target='_blank' rel='noreferrer' className='duration-300 hover:text-primary' onClick={()=>setSideBar(false)}>Catálogo GT</a>}
                        {catalog === 'koe' && <a href='https://drive.google.com/file/d/1NeozgDcdUQNboVlnC1WPUKYT87zmAAfV/view?usp=sharing'
                            target='_blank' rel='noreferrer' className='duration-300 hover:text-primary' onClick={()=>setSideBar(false)}>Catálogo ON</a>}

                        {/* canales */}
                        {/* <div className='font-bold tracking-wide uppercase text-xxs text-slate-400'>canales</div> */}
                        {/* <a target='_blank' rel='noreferrer' href={`https://wa.me/+51993375762`} className='duration-300 hover:text-primary'>Linea KOE</a> */}
                        {/* <a target='_blank' rel='noreferrer' href={`https://wa.me/+51993375762`} className='duration-300 hover:text-primary'>Linea KOA</a> */}
                        {/* <a target='_blank' rel='noreferrer' href={'mailto:soporte@trade.com'} className='duration-300 hover:text-primary'>soporte@trade.com</a> */}
                        {/* <div className='w-full h-[1px] bg-slate-200'></div> */}

                        {/* logout */}
                        <div className='cursor-pointer opacity-40' onClick={() => {
                            localStorage.removeItem('auth')
                            window.location.href = '/'
                        }}>Logout</div>
                    </div>
                    <div onClick={() => setSideBar(false)} className="fixed top-0 bottom-0 left-0 right-0 flex bg-black bg-opacity-60 backdrop-blur z-[99999]" />
                </Modal>
            )
            }
            {/* NEWSIDEBAR */}

            <div className="h-5 print:h-[20px] bg-gradient-to-r from-yellow-300 to-yellow-500 forced-white-printable"></div>
            <nav className="mx-auto h-[120px] print:h-[50px] flex justify-between print:justify-center flex-row items-center print:mt-[30px] gap-4 max-w-[90%]">
                {catalog ? <UIBackButton /> : <div></div>}
                {catalog ? (<Link to={`/${catalog}`}> <img className="h-10 print:h-6" src="/assets/logos/logo.svg" alt="logo" /> </Link>) : (<img className="h-10 print:h-6" src="/assets/logos/logo.svg" alt="logo" />)}

                {catalog && (
                    <div className="items-center justify-end flex-grow hidden w-full h-8 gap-1 text-sm font-bold uppercase md:flex">
                        <NavbarLinks />
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" onClick={() => setSideBar(true)} className="w-6 h-6 cursor-pointer"> <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" /> </svg>
                    </div>
                )}

                {/* responsive menu toogle */}
                {catalog ? (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" onClick={() => setSideBar(true)} className="w-10 h-10 cursor-pointer md:hidden print:hidden"> <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" /> </svg>) : (<div></div>)}

                {/* responsive menu */}
                {openMenu && (
                    <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex flex-col items-center justify-center h-screen text-sm font-bold uppercase transform bg-white animate-menu-appear">
                        <NavbarLinks />

                        {/* custom on responsive */}
                        <div className='font-bold tracking-wide uppercase text-xxs text-slate-400'>herramientas</div>
                        <Link to={'/herramientas/medidas'} className='duration-300 hover:text-primary'>Pirámide de Precios</Link>
                        <Link to={'/herramientas/medidas'} className='duration-300 hover:text-primary'>Grilla</Link>
                        <Link to={'/herramientas/medidas'} className='duration-300 hover:text-primary'>Descargas</Link>
                        <div className='w-full h-[1px] bg-slate-200'></div>
                        <div className='font-bold tracking-wide uppercase text-xxs text-slate-400'>canales</div>
                        <a target='_blank' rel='noreferrer' href={`https://wa.me/+51993375762`} className='duration-300 hover:text-primary'>Linea KOE</a>
                        <a target='_blank' rel='noreferrer' href={`https://wa.me/+51993375762`} className='duration-300 hover:text-primary'>Linea KOA</a>
                        <a target='_blank' rel='noreferrer' href={'mailto:soporte@trade.com'} className='duration-300 hover:text-primary'>soporte@trade.com</a>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" onClick={() => { setOpenMenu(!openMenu); }} className="w-6 h-6 mb-10 cursor-pointer stroke-black hover:stroke-yellow-500"> <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" /> </svg>
                    </div>
                )}
            </nav>
        </header >
    );
}