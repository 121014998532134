import { useState } from 'react';

const UIInputOutSelect = ({
    label,
    int = false,
    initialValue = '',
    disabled = false,
    className,
    placeholder = '',
    value,
    handleChange = () => console.log('selected'),
    options = [],
}) => {
    const [InputValue, setInputValue] = useState(value);
    const [onFocus, setOnFocus] = useState(false);
    const [open, setOpen] = useState(false);

    const onChange = (e) => {
        setInputValue(e.target.name);
        handleChange(e.target.name);
        setOpen(true);
    };

    const selectOption = (e) => {
        setInputValue(e.name);
        handleChange(e.value);
        setOpen(false);
    };

    return (
        <div className={`flex  w-full flex-col gap-1 ${className}`}>
            <label className="text-xxs font-bold text-[#535353] tracking-tight">
                {label}
            </label>
            <div className="relative h-[40px] flex">
                {onFocus && (
                    <div className="absolute w-full h-full animate-pulse blur-sm bg-primary-100"></div>
                )}
                <div
                    className={`absolute z-10 flex w-2 h-2 rotate-45 -translate-y-1 border-gray-400 border-b-2 border-r-2 right-3 top-1/2 border-primary-500`}
                />
                <div className="absolute flex w-full h-full py-1 transition-all bg-white border border-gray-400 rounded hover:shadow-xl">
                    <input
                        onClick={() => setOpen(true)}
                        disabled={disabled}
                        onFocus={() => {
                            setOnFocus(true);
                            setOpen(true);
                        }}
                        onBlur={() => {
                            setOnFocus(false);
                            // setOpen(false);
                        }}
                        className={`px-4 cursor-pointer text-sm ${
                            int && 'text-right'
                        }
                        ${
                            disabled
                                ? 'text-slate-400 cursor-not-allowed bg-slate-200 border-slate-200 border '
                                : 'bg-white'
                        } w-full outline-none transition-all rounded `}
                        type="text"
                        value={InputValue}
                        placeholder={placeholder}
                        onChange={onChange}
                    />
                    {open && (
                        <ul
                            onMouseLeave={() => setOpen(false)}
                            className={`absolute w-full top-[38px] z-40 rounded max-h-[250px] overflow-auto bg-white shadow-lg border border-slate-200 custom-scroll`}>
                            {options.map((option, index) => (
                                <li
                                    key={index}
                                    className="p-2 px-4 text-xs cursor-pointer text-slate-600 hover:bg-primary-100 hover:bg-slate-200"
                                    onClick={() => selectOption(option)}>
                                    {option.name}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UIInputOutSelect;
